<template>
    <div class="faq-page">
        <Breadcrumb titulo="Duvidas frequentes" :items="item" />


        <b-row>
            <b-col>
                <div class="accordion" role="tablist">
                    <b-card no-body class="mb-1" v-for="(items,index) in dadosAcordeon">
                        <b-card-header header-tag="header" class="p-1" role="tab">
                            <b-button block v-b-toggle="'accordion_' + index" variant="info" class="btn_faq_nivel1">
                                <span class="when-open"><i class="fas fa-chevron-down"></i></span><span class="when-closed"><i class="fas fa-chevron-right"></i></span>
                                {{items.tituloPrincipal}}
                            </b-button>
                        </b-card-header>
                        <b-collapse :id="'accordion_' + index" accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                                <b-card-text>
                                    <div class="accordion" role="tablist">
                                        <b-card no-body class="mb-1" v-for="(item) in items.valor">
                                            <b-card-header header-tag="header" class="p-1" role="tab">
                                                <b-button block v-b-toggle="'accordion__'+'_'+ index +'_' + item.index" variant="info" class="btn_faq_nivel1">
                                                    <span class="when-open sinal_menos"><i class="fas fa-minus"></i></span><span class="when-closed"><i class="fas fa-plus"></i></span>
                                                    {{item.titulo}}
                                                </b-button>
                                            </b-card-header>
                                            <b-collapse :id="'accordion__'+'_'+ index +'_' + item.index" accordion="accordion_aten" role="tabpanel">
                                                <b-card-body>
                                                    <b-card-text>
                                                        <div class="caixa_texto_faq">
                                                            {{item.descricao}}
                                                        </div>
                                                    </b-card-text>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>
                                    </div>
                                </b-card-text>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                </div>


            </b-col>
            <b-col cols="12" md="auto">
                <!--vIDEOS-->
            </b-col>
            <b-col col lg="2">
                <!--vIDEOS-->
            </b-col>
        </b-row>


    </div>
</template>

<script>
    import Breadcrumb from '@/components/breadcrumb/Breadcrumb.vue'


    export default {
        name: 'FaqPage',

        components: {
            Breadcrumb,

        },

        data: function () {
            return {
                item: [{
                    id: '2',
                    classe: 'fas fa-user-cog',
                    texto: 'Duvidas',
                    link: '/faq'
                }],
                dadosAcordeon: [{
                    tituloPrincipal: "Atendimento",
                    valor: [
                        { index: 1, titulo: "Como sei que tenho uma nova mensagem?", descricao: " O sistema tem um aviso sonoro que indica a chegada de uma nova mensagem. Caso seja um novo atendimento, ele aparecerá junto com os demais atendimentos ativos. Caso seja uma nova mensagem em um atendimento já existente, haverá um aviso visual indicando." },
                        { index: 2, titulo: "Como preencher dados do cliente?", descricao: "Durante o atendimento, existirá uma janela à direita da tela com os dados do cliente e do atendimento chamada “Dados”.Os campos que aparecem para preenchimento podem ser alterados na parte “tabulação” do painel." },
                        { index: 3, titulo: "Como verificar o histórico do atendimento?", descricao: "À direita da área de atendimento, existe a área dos dados do cliente. Ao lado da aba “dados”, existe a aba “histórico”. Clique nele para mostrar o histórico do atendimento. " },
                        { index: 4, titulo: "Como saber o canal de origem do atendimento?", descricao: "À direita da área de atendimento, existe a área dos dados do cliente. Dentro da aba “dados”, existem algumas informações sobre o atendimento, entre elas, a origem do mesmo.  Você receberá atendimentos apenas de canais que já estejam previamente configurados para seu usuário.Independente da origem, todos os atendimentos são processados nessa mesma parte do painel. " },
                        { index: 5, titulo: "Como transferir um atendimento?", descricao: "Acima da caixa de mensagens, existe um botão chamado “ações”. Clique nele e selecione “encaminhar”. Um pop up se abrirá, onde você irá escolher para qual setor o atendimento será transferido.Ele aparecerá para um dos atendentes disponíveis.Não é possível escolher o atendente que receberá o atendimento. " },
                        { index: 6, titulo: "Como finalizar um atendimento?", descricao: "Na parte superior da área de atendimento, existe um botão chamado “finalizar atendimento”. Clique nele para finalizar um atendimento " },
                        {
                            index: 7, titulo: "Como pesquisar o histórico de atendimentos dos atendentes?", descricao: "No menu principal, à esquerda da tela, clique em “consultas” (item com ícone de lupa).  Depois clique em atendimentos. Você terá acesso a todos os atendimentos de seus agentes. Para pesquisar os atendimentos de um atendente específico, clique no campo “atendente” e escolha o usuário."
                        },
                    ]

                },

                {
                    tituloPrincipal: "Árvore de decisões",
                    valor: [
                        { index: 1, titulo: "Quem pode gerenciar as perguntas da árvore de decisões?", descricao: " Apenas usuários autorizados, de acordo com a hierarquia de usuários." },
                        { index: 2, titulo: "Como acessar as perguntas da árvore de decisões?", descricao: "No menu principal, à esquerda da tela, clique no segundo item. Se esse ícone não aparece para você, seu usário não tem a permissão necessária para essa tarefa. Fale com seu gestor ou com o usuário Master do sistema. " },
                        { index: 3, titulo: "Como criar uma nova pergunta?", descricao: "Na parte inferior, existe um botão chamado “+ nova pergunta”. Clique nele e uma janela para a criação da mesma aparecerá. Nela, você escreverá seu texto, com ferramentas de formatação básica. No campo “pergunta inicial”, você define se essa será a primeira mensagem enviada no atendimento. No campo 'tipo de pergunta' de preenchimento obrigatório), você escolherá a ação que essa pergunta gerará.São elas: Nenhum: nenhuma ação acontecerá, e a próxima mensagem será enviada automaticamente. Dado do cliente: sua mensagem perguntará algum dado do cliente, para adiantar o preenchimento da tabulação.Caso essa opção seja escolhida, escolha qual dado é esse no campo ao lado, “dado do cliente”. Seleção: Essa pergunta virá acompanhada de opções que o cliente deverá escolher uma para prosseguir no atendimento.Pode ser um sim/ não, ou uma seleção de departamento para encaminhar o atendimento ao atendente.Quando essa opção é selecionada, aparece um campo em popup para criar as opções de escolha, bem como qual será a próxima pergunta caso o usuário a escolha e sua ordenação. Caso seja selecionado “nenhum” ou “dado do cliente”, aparecerá um campo para indicar qual a próxima pergunta da árvore.É possível editar esse campo a qualquer momento. Após a criação, clique no botão “salvar”.Você pode editar essa pergunta a qualquer momento, encontrando- a na lista e clicando no ícone de lápis(edição)." },
                        { index: 4, titulo: "Como editar uma pergunta existente?", descricao: "Localize a pergunta desejada e, no canto direito da linha, clique no ícone do lápis. Uma janela semelhante a do cadastro se abrirá para você editar a pergunta. Após fazer as alterações, clique em “salvar”. Como saber qual é a pergunta inicial da árvore? No lado direito da tela, existe um fluxograma para melhor visualização da árvore.Não é possível editá- lo por ali.O primeiro atendimento estará visível." },
                        { index: 5, titulo: "Como fazer o auto cadastro do cliente?", descricao: "É possível solicitar dados do cliente, através das perguntas, para já deixar salvo no atendimento e agilizar o atendimento. Para isso, ao criar a pergunta, no campo “tipo de resposta”, selecione “dado do cliente”. No campo ao lado, também chamado “dado do cliente”, selecione o campo que será preenchido com a resposta dessa pergunta. As opções disponíveis nesse campo são as estabelecidas na parte de tabulação do painel de controle. Independentemente do que for escolhido no campo “dado do cliente”, a pergunta não é gerada automaticamente, sendo necessário preencher o campo de acordo com o padrão de comunicação adotado pela empresa." },
                        { index: 6, titulo: "Como criar opções para direcionar o cliente?", descricao: "Crie uma pergunta da maneira tradicional. No campo “tipo de resposta”, escolha a opção “seleção”. Abaixo desse campo aparecerão as possibilidades de criação para as respostas. Clique em “nova resposta” para adicionar mais opções. É possível criar as opções e editar posteriormente, caso não tenha o número da próxima pergunta ainda." },
                        {
                            index: 7, titulo: "Quais as possibilidades de criação da árvore de decisões?", descricao: "Através da criação de perguntas, é possível manter uma conversa entre o robô e o cliente. As opções de resposta são: Dado do cliente: sua mensagem perguntará algum dado do cliente, para adiantar o preenchimento da tabulação*.Caso essa opção seja escolhida, escolha qual dado é esse no campo ao lado, “dado do cliente”. Seleção: Essa pergunta virá acompanhada de opções que o cliente deverá escolher para prosseguir no atendimento.Pode ser um sim/ não, ou uma seleção de departamento para encaminhar o atendimento ao atendente.Após criar a mensagem, encontre - a na lista, e clique no último ícone(de lista) para criar as opções e direcionar à mensagem relacionada.Direcionar o atendimento para um atendente: após fazer uma triagem para entender qual departamento o cliente deverá ser encaminhado, o atendimento é enviado para um atendente disponível, do departamento em questão, para finalizar o atendimento manualmente.Para indicar qual atendimento fará essa mudança, encontre a mensagem no painel"
                        },
                        {
                            index: 8, titulo: "Como criar uma nova árvore diferente?", descricao: "No canto superior esquerdo da tela existe um botão “criar arvore”. Clique nele, de um nome para a arvore e escolha seu canal de atuação."
                        },
                        {
                            index: 9, titulo: "É possível ter mais de uma árvore para um único canal?", descricao: "No momento não é possível. "
                        },
                        {
                            index: 10, titulo: "Como navegar entre as árvores de diferentes canais?", descricao: "Na parte superior da tela, logo abaixo do botão “criar árvore” existem abas que correspondem a cada uma das árvores de decisão que você criou. Clique em cima do seu nome para abri-la e gerenciá-la."
                        },
                    ]

                },
                {
                    tituloPrincipal: "Layout do Chatbot",
                    valor: [
                        { index: 1, titulo: "Como alterar o visual do chat em meu site?", descricao: "No menu principal, à esquerda da tela, clique em “cad. básicos” (item com ícone de engrenagens).  Depois clique em “chatbox layout”. Do lado esquerdo da tela, existem opções para personalizar a imagem do ícone, os textos da saudação, e as cores principais." },
                        { index: 2, titulo: "Como alterar o cabeçalho do chat em meu site?", descricao: "No menu principal, à esquerda da tela, clique em “cad. básicos” (item com ícone de engrenagens).  Depois clique em “chatbox layout”. Do lado esquerdo da tela, existem opções de personalização, entre elas o campo de selecionar a imagem do bot, e “nome do bot” que aparece no topo do mesmo. Você tambem pode personalizar as cores dele e outras saudações. " },

                    ]

                },
                {
                    tituloPrincipal: "Usuários, canais e setores",
                    valor: [
                        {
                            index: 1, titulo: "Como cadastrar um novo atendente?", descricao: "No menu principal, à esquerda da tela, clique em “cad. básicos” (item com ícone de engrenagens). Depois clique em “usuário”.  Na tela serão exibidos todos os usuários, sendo possível alterar seus dados e permissões, ou até mesmo excluir um deles. Se essa opção não aparece para você significa que seu usuário não possui permissão para essa ação. Clique no botão “novo usuário” e uma janela para o cadastro se abrirá. Preencha com os dados do cliente, defina o número máximo de atendimentos ativos que ele possa ter, escolha seu supervisor e o seu nível na hierarquia.Após o cadastro, localize o usuário na lista e clique na seta ao lado de seu nome. Agora é necessário colocar o usuário em um setor e atribuir seus canais de atendimento.Para isso, clique em “novo setor” no canto direito. Escolha o canal que esse usuário atenderá(caso mais de um canal esteja atribuído a ele, repita o processo até o cadastro estar completo).Selecione também o departamento do usuário(um usuário só poderá estar em um departamento), e o nível de prioridade.Clique em “salvar”."
                        },
                        {
                            index: 2, titulo: "Como adicionar um supervisor a um atendente?", descricao: "No menu principal, à esquerda da tela, clique em “cad. básicos” (item com ícone de engrenagens). Depois clique em “usuário”.  Na tela serão exibidos todos os usuários, sendo possível alterar seus dados e permissões, ou até mesmo excluir um deles. Para cadastrar um novo atendente, clique no botão “novo usuário”, próximo à barra de busca.Preencha os dados do usuário e no campo “supervisor” seleciona a opção desejada.Para alterar ou atribuir um supervisor a um usuário já cadastrado, procure ele na lista, e clique no ícone do lápis no canto direito da tela.Uma janela com os dados do mesmo abrirá, e no campo “supervisor” você pode escolher a opção desejada.Após o processo, clique em “salvar”."
                        },
                        {
                            index: 3, titulo: "Como limitar o número de atendimentos ativos por atendente?", descricao: "No menu principal, à esquerda da tela, clique em “cad. básicos” (item com ícone de engrenagens). Depois clique em “usuário”.  Na tela serão exibidos todos os usuários, sendo possível alterar seus dados e permissões, ou até mesmo excluir um deles. Para cadastrar um novo atendente, clique no botão “novo usuário”, próximo à barra de busca.Preencha os dados do usuário e no campo “atendimentos” seleciona a opção desejada. Para alterar o número de atendimentos a um usuário já cadastrado, procure ele na lista, e clique no ícone do lápis no canto direito da tela.Uma janela com os dados do mesmo abrirá, e no campo “atendimentos” você pode escolher a opção desejada.Após o processo, clique em “salvar”."
                        },
                        {
                            index: 4, titulo: "Como atribuir um setor e canais a um atendente?", descricao: "No menu principal, à esquerda da tela, clique em “cad. básicos” (item com ícone de engrenagens). Depois clique em “usuário”.  Na tela serão exibidos todos os usuários, sendo possível alterar seus dados e permissões, ou até mesmo excluir um deles. Primeiro é necessário cadastrar o usuário.Clique no botão “novo usuário” e preencha seus dados. Procure o usuário desejado na lista e clique na seta ao lado de seu nome. Clique em “novo setor” no canto direito da tela para adicionar um setor e canais de atendimento ao usuário.É necessário repetir o processo para cada canal que o atendente está apto a atender."
                        },
                        {
                            index: 5, titulo: "Como pesquisar um usuário?", descricao: "Utilize a barra de busca no canto superior direito da tela. Nessa parte são exibidos todos os usuários, sendo possível alterar seus dados e permissões, ou até mesmo excluir um deles. Como adicionar uma assinatura de email a um usuário? No menu principal, à esquerda da tela, clique em “cad.básicos” (item com ícone de engrenagens).Depois clique em “usuário”.Na tela serão exibidos todos os usuários, sendo possível alterar seus dados e permissões, ou até mesmo excluir um deles. Cadastre o usuário em questão, ou procure por ele na lista.Clique na seta localizada no canto esquerdo da linha.Depois clique no botão “assinatura” do lado direito.Um pop up abrirá com um editor de texto simples para adicionar a assinatura desse usuário.Ao finalizar, clique em “salvar”."
                        },
                        {
                            index: 6, titulo: "Como criar um novo setor?", descricao: "No menu principal, à esquerda da tela, clique em “cad. básicos” (item com ícone de engrenagens). Depois clique em “setor”.  Na tela serão exibidos todos os setores, sendo possível alterá-los ou até mesmo excluir um deles. Para criar um novo setor, clique no botão “criar” no canto superior direito.Será necessário preencher o nome do setor, e as informações relativas ao servidor de email(entrada e saida) para que o sistema tenha a autonomia de mostrar as mensagens recebidas e enviar emails com as repostas dos atendimentos, bem como as configurações de Whatsapp.O preenchimento desses campos só é obrigatório caso utilize os canais. "
                        },
                        {
                            index: 6, titulo: "Como adicionar canais de atendimento a um setor?", descricao: "No menu principal, à esquerda da tela, clique em “cad. básicos” (item com ícone de engrenagens). Depois clique em “canal”.  Na tela serão exibidos todos os canais de atendimento, sendo possível alterá-los ou até mesmo excluir um deles. Para criar um novo canal, clique no botão “criar” no canto direito.Será necessário adicionar uma descrição, ou nome, e selecionar o tipo do canal na lista de canais disponíveis para você.Clique em “salvar”. Procure o canal que você acabou de criar(ou o que já existe, se for o caso) na lista e, na coluna “setor” clique em “adicionar setor” ou “alterar”.Um popup aparecerá e você pode escolher aí qual o setor responsável por esse canal. Posso criar mais vários canais de apenas um tipo(whatsapp, email, etc)? Sim. Cada canal só pode ser vinculado a um setor.Então se você tem 3 setores em sua empresa, e todos eles atendem e- mail, será necessário ter 3 canais de e - mail."
                        },
                        {
                            index: 7, titulo: "Como adicionar campos na tabulação?", descricao: "No menu principal, à esquerda da tela, clique em “cad. básicos” (item com ícone de engrenagens). Depois clique em “tabulação”.  Na tela serão exibidos todos os campos de tabulação, sendo possível alterá-los ou até mesmo excluir um deles. Para criar um novo campo de tabulação, clique no botão “nova tabulação” no canto direito.Uma janela se abrirá para você preencher com as configurações do novo campo.O título do campo é seu nome, que aparecerá para o atendente.Selecione se esse é um campo de preenchimento obrigatório para finalizar um atendimento ou transferi- lo.Em “tipo”, selecione se o campo é apenas números, texto, uma data, etc.No campo “campo cliente”, selecione se esse item é um dos documentos listados, ou nenhum deles.Isso é necessário para adicionar a validação, caso seja um campo de cpf por exemplo, para evitar fraudes.Selecione também se ele tem alguma dependência com outras ferramentas, e depois clique em “salvar’ para finalizar o cadastro."
                        },
                    ]

                },
                {
                    tituloPrincipal: "Kanban",
                    valor: [
                        {
                            index: 1, titulo: "Onde posso acessar a ferramenta Kanban?", descricao: "No menu lateral esquerdo do painel, vá no penúltimo ícone (uma tabela). A ferramenta Kanban abrirá, com a opção de abas para navegar por diferentes tipos de Kanbans. Selecione a aba desejada e mova os cards de atendimentos arrastando-os entre as colunas."
                        },
                        {
                            index: 2, titulo: "Como adicionar campos para as colunas do kanban?", descricao: "É possível ter apenas um conjunto de colunas. Para cria-lo, vá em Cadastros básicos > Tabulação. Escolha um campo do tipo “Lista suspensa” ou crie um especial para o Kanban. Para criar um novo campo, vá em “nova tabulação”.Dê um nome para ela.No campo “tipo de resposta”, escolha lista suspensa, e no campo “campo cliente” escolha a opção nenhum.Clique em salvar, depois encontre o campo novo na lista, e clique na seta do lado esquerdo, abaixo do nome dele.Um espaço abrirá, com um botão do lado direito, chamado “novo item”.Clique nele para criar as colunas do Kanban, e repita o processo o quanto for necessário.Para finalizar, clique na bolinha na coluna “funil” para o sistema entender esse campo como padrão para o Kanban. Esse processo só é necessário ser realizado uma vez, podendo ser editado a qualquer momento."
                        },
                        {
                            index: 3, titulo: "Como mudar o atendimento de coluna?", descricao: "Basta arrastar o retângulo do atendimento para a coluna correspondente. Clique nele e mantenha clicado enquanto faz o movimento com o mouse para movê-lo."
                        },
                        {
                            index: 4, titulo: "É possível ter mais de um kanban?", descricao: "No momento não, apenas um grupo de colunas."
                        },
                        {
                            index: 5, titulo: "Eu posso mover um atendimento para uma nova aba do kanban?", descricao: "Não. As alterações só podem ser feitas dentro da mesma aba."
                        },

                    ]

                },
                {
                    tituloPrincipal: "Integrações Externas",
                    valor: [
                        {
                            index: 1, titulo: "Como integrar com o Facebook?", descricao: "No menu lateral esquerdo, clique no terceiro ícone (cadastros básicos, com a engrenagem). Depois clique em “configurações”. Procure por Facebook na lista à esquerda, clique em login, entre com seu login e senha e siga as instruções da tela."
                        },
                        {
                            index: 2, titulo: "Como adicionar meu Instagram?", descricao: "No menu lateral esquerdo, clique no terceiro ícone (cadastros básicos, com a engrenagem). Depois clique em “configurações”. Procure por Instagram na lista à esquerda, clique em login, entre com seu login e senha e siga as instruções da tela."
                        },
                        {
                            index: 3, titulo: "Como integrar com o Mercado Livre?", descricao: "No menu lateral esquerdo, clique no terceiro ícone (cadastros básicos, com a engrenagem). Depois clique em “configurações”. Procure por Mercado Livre na lista à esquerda, clique em “autorizar” e siga as instruções da tela"
                        },
                        {
                            index: 4, titulo: "Como integrar com minha conta Nuvemshop?", descricao: "No menu lateral esquerdo, clique no terceiro ícone (cadastros básicos, com a engrenagem). Depois clique em “configurações”. Procure por Nuvemshop na lista à esquerda, e siga as instruções da tela. "
                        },

                    ]

                },

                ]

            }
        },





        methods: {

        },
    }
</script>

<style scoped>

    .card {
        height: auto !important;
    }

    .card-header {
        border-bottom: none !important;
        background-color: transparent !important;
    }

    .btn {
        border-radius: 0px !important;
        width: 97.5%;
        margin: 0px auto;
    }

    .btn_faq_nivel1 {
        background-color: #e5e5eb;
        color: #000;
        font-family: Verdana;
        font-weight: bold;
        text-align: left;
        font-size: 12px;
        border: 0px;
    }

        .btn_faq_nivel1:active {
            background-color: #e5e5eb !important;
            color: #000 !important;
        }

        .btn_faq_nivel1:focus {
            box-shadow: none !important;
        }

    .collapsed > .when-open,
    .not-collapsed > .when-closed {
        display: none;
    }

    .caixa_texto_faq {
        background-color: #e5e5eb;
        color: #000;
        font-family: Verdana;
        margin: -24px auto;
        padding: 26px;
        width: 100%;
    }
</style>